// JavaScript Document

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faBars as farFaBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { faTimes as farFaTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faAngleDown as fasFaAngleDown } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faCommentDots as fasFaCommentDots } from "@fortawesome/pro-solid-svg-icons/faCommentDots";
import { faPhone as fasFaPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";

/**
 * Add regular icons
 */
library.add(farFaBars, farFaTimes);

/**
 * Add solid icons
 */
library.add(fasFaAngleDown, fasFaPhone, fasFaCommentDots);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
